import { api } from "../../../api/api";
import { HOST_NAME, socketProtocol } from "../../../api/baseQuery";
import store from "../../../store";

export type AIConversation = {
    id: number;
    created: string;
    modified: string;
    user: number;
    title: string;
    status: "active" | "completed" | "archived";
};

export type AIMessage = {
    content: string;
    conversation: number;
    created: string;
    id: number;
    role: "assistant" | "user" | "system";
};

type AssistantSocketData = {
    conversation: AIConversation | null;
    conversations: AIConversation[];
    messages: AIMessage[];
    streamMessage: string | null;
    isTyping: boolean;
};

export enum AssistantMessageTypes {
    GetConversations = "get_conversations",
    GetMessagesFromConversation = "get_messages_for_conversation",
    StartChat = "start_chat",
    TextMessage = "text_message",
    StreamMessage = "stream_message",
    AIIsTyping = "ai_is_typing"
}

export const SOCKET_ASSISTANT_TAG = "AssistantSocketConnection";

export let wsAssistant: WebSocket;

export const sendToAssistantWs = (data: Record<string, string>) => {
    if (!wsAssistant) return;
    if (wsAssistant.readyState !== WebSocket.OPEN) return;
    wsAssistant.send(JSON.stringify(data));
};

export const getAssistantSocket = () => {
    const token = store.getState().auth.authToken;

    const url = `${socketProtocol}${HOST_NAME}/chat/assistant/?token=${token}`;
    return new WebSocket(url);
};
export const assistantWsApi = api
    .enhanceEndpoints({
        addTagTypes: [SOCKET_ASSISTANT_TAG]
    })
    .injectEndpoints({
        endpoints: build => ({
            getAssistantSocketConnection: build.query<AssistantSocketData, void>({
                queryFn: () => ({
                    data: {
                        conversation: null,
                        messages: [],
                        conversations: [],
                        isTyping: false,
                        streamMessage: null
                    }
                }),

                async onCacheEntryAdded(_arg, { cacheEntryRemoved, cacheDataLoaded, updateCachedData }) {
                    const socket = getAssistantSocket();
                    wsAssistant = socket;

                    const onOpen = () => {
                        console.info("%cCONNECTION IS OPENED", "color: green");
                    };
                    const onClose = () => {
                        console.info("%cCONNECTION CLOSE", "color: salmon");
                    };
                    const onError = (event: any) => {
                        console.info("Error", event);
                    };
                    const onMessage = (event: any) => {
                        const data = JSON.parse(event.data);
                        console.info("Message", data);

                        switch (data.msg_type) {
                            case AssistantMessageTypes.GetConversations:
                                const { conversations } = data;
                                updateCachedData(draft => {
                                    draft.conversations = conversations;
                                });
                                break;

                            case AssistantMessageTypes.StreamMessage:
                                const { message: streamMessage } = data;
                                updateCachedData(draft => {
                                    draft.streamMessage += streamMessage;
                                });
                                break;

                            case AssistantMessageTypes.GetMessagesFromConversation:
                                const { messages, conversation: currentConversation } = data;
                                updateCachedData(draft => {
                                    draft.messages = messages;
                                    draft.conversation = currentConversation;
                                });
                                break;

                            case AssistantMessageTypes.StartChat:
                                const { message, conversation } = data;
                                updateCachedData(draft => {
                                    draft.messages.push(message);
                                    draft.conversation = conversation;
                                    draft.conversations.push(conversation);
                                });
                                break;

                            case AssistantMessageTypes.TextMessage:
                                const { message: userMessage } = data;
                                updateCachedData(draft => {
                                    draft.messages.push(userMessage);
                                    if (draft.streamMessage) {
                                        draft.streamMessage = null;
                                    }
                                });
                                break;

                            case AssistantMessageTypes.AIIsTyping:
                                const { is_typing } = data;
                                updateCachedData(draft => {
                                    draft.isTyping = is_typing;
                                });
                                break;
                            default:
                                console.log("Default");
                                break;
                        }
                    };

                    try {
                        await cacheDataLoaded;
                        socket.addEventListener("close", onClose);
                        socket.addEventListener("error", onError);
                        socket.addEventListener("message", onMessage);
                        socket.addEventListener("open", onOpen);
                    } catch (error) {
                        console.warn("Error occurred", error);
                    }
                    await cacheEntryRemoved;
                    socket.close();
                },
                providesTags: [SOCKET_ASSISTANT_TAG]
            })
        })
    });

export const { useGetAssistantSocketConnectionQuery } = assistantWsApi;

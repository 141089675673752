import { Button, HStack, Heading, Text, VStack, useToast } from "@chakra-ui/react";
import { useState } from "react";

import { useMultiCountrySelect } from "../../../hooks/useCountrySelect";
import { useMultipleEnergyTypeSelect } from "../../../hooks/useEnergyTypeSelect";
import { useMultiStatusSelect } from "../../../hooks/useStatusSelect";
import { useGetUserProfileQuery, useUpdateProfileMutation } from "../../home/api/usersApi";

export const PreferencesDetails = () => {
    const toast = useToast();
    const { data: profile } = useGetUserProfileQuery();
    const [editMode, setEditMode] = useState(false);
    const [updateProfile, { isLoading: loadingUpdate }] = useUpdateProfileMutation();

    const { EnergyTypeSelect, values } = useMultipleEnergyTypeSelect(
        profile?.energy_type_focus.map(item => ({
            label: item.name,
            value: String(item.id)
        }))
    );

    const { CountrySelect, values: countryIds } = useMultiCountrySelect(
        profile?.region_focus.map(item => ({
            label: item.name,
            value: String(item.id)
        }))
    );

    const { ProjectStatusSelect, values: statusIds } = useMultiStatusSelect(
        profile?.status_focus.map(item => ({
            label: item.name,
            value: String(item.id)
        }))
    );

    const onUpdatePreferences = async () => {
        try {
            await updateProfile({ energy_type_focus: values, region_focus: countryIds, status_focus: statusIds }).unwrap();
            setEditMode(false);
            toast({
                title: "Preferences updated",
                status: "success",
                duration: 3000,
                isClosable: true
            });
        } catch (err) {
            toast({
                title: "Failed to update preferences",
                status: "error",
                duration: 3000,
                isClosable: true
            });
            return err;
        }
    };

    const preferencesFields = [
        {
            title: "Country focused on",
            content: <VStack>{editMode ? CountrySelect : <Text>{profile?.region_focus.map(item => item.name).join(", ")}</Text>}</VStack>
        },
        {
            title: "Energy type relevant for me",
            content: (
                <VStack>
                    {editMode ? (
                        EnergyTypeSelect
                    ) : (
                        <Text>{profile?.energy_type_focus ? profile?.energy_type_focus.map(item => item.name).join(", ") : "Not set"}</Text>
                    )}
                </VStack>
            )
        },
        {
            title: "Project stage interested in",
            content: (
                <VStack>
                    {editMode ? (
                        ProjectStatusSelect
                    ) : (
                        <Text>{profile?.status_focus ? profile?.status_focus.map(item => item.name).join(", ") : "Not set"}</Text>
                    )}
                </VStack>
            )
        },
        {
            title: "Project capacity range",
            content: (
                <VStack>
                    <Text>{profile?.capacity_range_focus ? profile?.capacity_range_focus : "Not set"}</Text>
                </VStack>
            )
        }
    ];
    return (
        <VStack gap='4' h='full' justifyContent='space-between'>
            <VStack gap='4' w='full'>
                {preferencesFields.map(({ title, content }) => (
                    <VStack key={title} w='100%' gap='2' alignItems='flex-start'>
                        <Heading size='sm'>{title}</Heading>
                        {content}
                    </VStack>
                ))}
            </VStack>
            {editMode ? (
                <HStack w='full'>
                    <Button colorScheme='red' onClick={() => setEditMode(false)} flexGrow='1'>
                        Cancel
                    </Button>
                    <Button colorScheme='green' flexGrow='1' onClick={onUpdatePreferences} isLoading={loadingUpdate}>
                        Save
                    </Button>
                </HStack>
            ) : (
                <Button w='full' onClick={() => setEditMode(true)}>
                    Edit
                </Button>
            )}
        </VStack>
    );
};

import { Box, Flex, HStack, Heading, Spinner, VStack } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

type PageLayoutProps = {
    children: React.ReactNode;
    title: string;
    rightAction?: React.ReactNode;
    isLoading?: boolean;
    onEndReached?: () => void;
};
export const PageLayout = ({ children, title, rightAction, isLoading, onEndReached }: PageLayoutProps) => {
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            const box = scrollRef.current;

            if (box && box.scrollTop + box.clientHeight >= box.scrollHeight) {
                onEndReached?.();
            }
        };

        scrollRef.current?.addEventListener("scroll", handleScroll);

        return () => {
            if (scrollRef.current) {
                scrollRef.current?.removeEventListener("scroll", handleScroll);
            }
        };
    }, [onEndReached]);

    return (
        <VStack spacing='4' w='full' h='full' position='relative'>
            <Flex
                justifyContent='space-between'
                alignItems='center'
                w='full'
                borderRadius='lg'
                direction={{ base: "column-reverse", md: "row" }}
                gap='1rem'>
                <Heading minW='10rem' size='xs'>
                    {title}
                </Heading>
                <Box alignSelf={{ base: "flex-end", md: "center" }}>{rightAction}</Box>
            </Flex>

            <Box borderRadius='md' w='full' height='full' overflowY='auto' ref={scrollRef}>
                {isLoading ? (
                    <HStack justifyContent='center' alignItems='center' height='full'>
                        <Spinner />
                    </HStack>
                ) : (
                    children
                )}
            </Box>
        </VStack>
    );
};

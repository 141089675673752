import { Box, HStack, Text } from "@chakra-ui/react";
import { useRef } from "react";

import { UploadIcon } from "../assets/icons/upload";

type UploadDocProps = {
    handleChange: React.ChangeEventHandler<HTMLInputElement>;
    isMultiple?: boolean;
};

export const UploadDoc = ({ handleChange, isMultiple }: UploadDocProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const handleClick = () => {
        hiddenFileInput.current?.click();
    };
    return (
        <>
            <button className='button-upload' onClick={handleClick} style={{ width: "100%" }}>
                <Box borderWidth='2px' borderStyle='dashed' borderColor='green.400' borderRadius='lg' p='4'>
                    <HStack justifyContent='center' mb='1rem'>
                        <UploadIcon size={50} />
                    </HStack>
                    <HStack alignItems='center' spacing='5px' justifyContent='center' h='full'>
                        <Text fontWeight='bold' textDecoration='underline'>
                            Click to upload
                        </Text>
                        <Text>or drag and drop</Text>
                    </HStack>
                </Box>
            </button>
            <input ref={hiddenFileInput} type='file' multiple={isMultiple} style={{ display: "none" }} onChange={handleChange} />
        </>
    );
};

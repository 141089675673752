import { CloseIcon } from "@chakra-ui/icons";
import { Box, HStack, IconButton, Input, InputGroup, InputRightElement, Slide, Text, VStack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import React from "react";

import { SendIcon } from "../../../assets/icons/send";
import { useAppDispatch } from "../../../store";
import { useAuthentication } from "../../auth/hooks/useAuthentication";
import {
    AIMessage,
    AssistantMessageTypes,
    assistantWsApi,
    sendToAssistantWs,
    useGetAssistantSocketConnectionQuery,
    wsAssistant
} from "../../websocket/api/assistantSocketApi";

type ChatBotProps = {
    countryCode: string;
    isVisible: boolean;
    onClose: () => void;
};

export const ChatBot = ({ countryCode, isVisible, onClose }: ChatBotProps) => {
    const { isAuthenticated } = useAuthentication();
    const chatRef = useRef<HTMLDivElement>(null);

    const [msgText, setMsgText] = useState("");

    const dispatch = useAppDispatch();

    const { data } = useGetAssistantSocketConnectionQuery(undefined, {
        skip: !isAuthenticated
    });

    const isTyping = Boolean(data?.isTyping);

    const sendMsgText = () => {
        if (!msgText) return;
        if (!data?.conversation) {
            sendToAssistantWs({
                msg_type: AssistantMessageTypes.StartChat,
                country: countryCode,
                msg: msgText
            });
        } else {
            sendToAssistantWs({
                msg_type: AssistantMessageTypes.TextMessage,
                msg: msgText,
                conversation_id: String(data.conversation.id)
            });
        }

        setMsgText("");
    };

    const onNewConversation = () => {
        dispatch(
            assistantWsApi.util.updateQueryData("getAssistantSocketConnection", undefined, oldData => ({
                ...oldData,
                conversation: null,
                messages: []
            }))
        );
    };

    const onConversationClick = (conversationId: number) => {
        sendToAssistantWs({
            msg_type: AssistantMessageTypes.GetMessagesFromConversation,
            conversation_id: String(conversationId)
        });
    };

    useEffect(() => {
        if (wsAssistant?.readyState !== WebSocket.OPEN) return;
        sendToAssistantWs({
            msg_type: AssistantMessageTypes.GetConversations,
            country: countryCode
        });
    }, [wsAssistant?.readyState]);

    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTo({
                top: chatRef.current.scrollHeight,
                behavior: "smooth"
            });
        }
    }, [data?.messages, data?.streamMessage]);

    return (
        <Box position='relative' zIndex='20'>
            <Slide direction='right' in={isVisible}>
                <HStack position='absolute' top='2rem' bottom='2rem' right='2rem'>
                    <VStack
                        p='1rem'
                        w='20rem'
                        borderWidth='1px'
                        borderColor='black'
                        h='full'
                        backgroundColor='white'
                        marginRight='-2rem'
                        borderRadius='xl'
                        overflowY='auto'
                        pr='3rem'>
                        {data?.conversations && (
                            <Text
                                cursor='pointer'
                                p='2'
                                borderRadius='xl'
                                backgroundColor='green.400'
                                w='full'
                                textAlign='center'
                                color='white'
                                fontWeight='bold'
                                position='sticky'
                                top='0'
                                onClick={onNewConversation}
                                mb='3'>
                                New conversation
                            </Text>
                        )}
                        {data?.conversations.map(conv => (
                            <Text
                                w='full'
                                key={conv.id}
                                cursor='pointer'
                                p='2'
                                onClick={() => onConversationClick(conv.id)}
                                borderRadius='xl'
                                backgroundColor='gray.200'>
                                {conv.title}
                            </Text>
                        ))}
                    </VStack>
                    <VStack
                        width='30rem'
                        height='full'
                        borderWidth='1px'
                        borderColor='black'
                        borderRadius='xl'
                        boxShadow='2xl'
                        backgroundColor='white'
                        p='1rem'>
                        <HStack mb='2' justifyContent='space-between' w='full'>
                            <Text fontSize='md' fontWeight='semibold'>
                                {data?.conversation?.title}
                            </Text>
                            <VStack p='2' borderWidth='1px' borderRadius='lg' cursor='pointer' onClick={onClose}>
                                <CloseIcon fontSize='xs' />
                            </VStack>
                        </HStack>

                        <VStack gap='1rem' h='full' w='full'>
                            <VStack w='full' flexGrow='1' maxHeight='80vh' overflowY='auto' ref={chatRef}>
                                {data && data?.messages.length > 0 ? (
                                    data?.messages.map(msg => <ChatBubble key={msg.id} message={msg} />)
                                ) : (
                                    <ChatBubble
                                        message={{
                                            role: "assistant",
                                            content: "Hello, How can I help you today?",
                                            conversation: 0,
                                            created: "",
                                            id: 0
                                        }}
                                    />
                                )}
                                {data?.streamMessage && (
                                    <ChatBubble
                                        message={{
                                            role: "assistant",
                                            content: data.streamMessage,
                                            conversation: 0,
                                            created: "",
                                            id: 0
                                        }}
                                    />
                                )}
                                {isTyping && <Text alignSelf='flex-start'>AI assistant is typing...</Text>}
                            </VStack>
                            <form
                                onSubmit={e => {
                                    e.preventDefault();
                                    sendMsgText();
                                }}
                                style={{ width: "100%" }}>
                                <InputGroup>
                                    <Input placeholder='Your message' value={msgText} onChange={e => setMsgText(e.target.value)} />
                                    <InputRightElement>
                                        <IconButton aria-label='send-message' icon={<SendIcon />} colorScheme='none' onClick={sendMsgText} />
                                    </InputRightElement>
                                </InputGroup>
                            </form>
                        </VStack>
                    </VStack>
                </HStack>
            </Slide>
        </Box>
    );
};

type ChatBubbleProps = {
    message: AIMessage;
};

const ChatBubble = ({ message }: ChatBubbleProps) => {
    const isUser = message.role === "user";

    const content = message.content.split("\n").map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
    return (
        <HStack
            p='2'
            borderRadius='2xl'
            borderWidth='1px'
            borderColor='gray.200'
            alignSelf={isUser ? "flex-end" : "flex-start"}
            backgroundColor={isUser ? "gray.100" : "transparent"}>
            {content}
        </HStack>
    );
};

import { Circle, VStack } from "@chakra-ui/react";

import { AIIcon } from "../../../assets/svg/ai";

type ButtonToChatProps = {
    onClick: () => void;
};

export const ButtonToChat = ({ onClick }: ButtonToChatProps) => {
    return (
        <VStack
            boxShadow={{ base: "none", md: "#34CC82 0px 0px 2rem" }}
            zIndex='10'
            cursor='pointer'
            p='2'
            onClick={onClick}
            position='fixed'
            bottom='5rem'
            right='3rem'
            borderRadius='full'
            borderWidth='1px'
            bg='green.300'
            borderColor='green.100'>
            <Circle size='3rem'>
                <AIIcon color='white' />
            </Circle>
        </VStack>
    );
};


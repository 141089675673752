import { api } from "../../../api/api";
import { HOST_NAME, socketProtocol } from "../../../api/baseQuery";
import store from "../../../store";
import { RootState } from "../../../store/state";
import { DIALOGS_LIST_TAG, chatApi } from "../../chat/api/chatApi";
import { DialogsResponse } from "../../chat/api/types";
import {
    setChatUser,
    setCurrentUserName,
    setGoToDialog,
    setIsTyping,
    setMessageNboStatus,
    setMessages,
    setNewMessage,
    setNextPage
} from "../../chat/data/chatSlice";
import { ACCEPTED_NBO_TAG, PENDING_NBO_TAG, PROJECT_DETAILS_TAG } from "../../projects/api/projectsApi";
import { UserType } from "../../users/api/types";
import { setConnectionLoading, setConnectionStatus, setNBOCreated } from "../data/socketSlice";

export enum MessageTypes {
    WentOnline = "went_online",
    WentOffline = "went_offline",
    TextMessage = "text_msg",
    FileMessage = "file_msg",
    IsTyping = "is_typing",
    MessageRead = "msg_read",
    ErrorOccurred = "error",
    MessageIdCreated = "msg_created",
    NewUnreadCount = "new_unread",
    TypingStopped = "typing_stopped",
    GetMessageList = "get_message_list",
    GetConnections = "get_connections",
    DialogCreated = "dialog_created",
    CreateNbo = "create_nbo",
    SetNBOStatus = "set_nbo_status"
}

export const SOCKET_TAG = "SocketConnection";

export let ws: WebSocket;

export const sendToWs = (data: Record<string, string>, setToTrue: boolean = true) => {
    if (!ws) return;
    if (ws.readyState !== WebSocket.OPEN) return;
    const isLoading = store.getState().socket.isLoading;

    if (!isLoading && setToTrue) {
        store.dispatch(setConnectionLoading(true));
    }
    ws.send(JSON.stringify(data));
};

export const getSocket = () => {
    const token = store.getState().auth.authToken;

    const url = `${socketProtocol}${HOST_NAME}/chat/?token=${token}`;
    return new WebSocket(url);
};

export const wsApi = api
    .enhanceEndpoints({
        addTagTypes: [SOCKET_TAG]
    })
    .injectEndpoints({
        endpoints: build => ({
            getSocketConnection: build.query<any, void>({
                queryFn: _ => ({ data: [] }),
                async onCacheEntryAdded(_arg, { dispatch, cacheEntryRemoved, getState, cacheDataLoaded }) {
                    const socket = getSocket();
                    ws = socket;

                    const onOpen = () => {
                        console.warn("%cCONNECTION IS OPENED", "color: green");
                        dispatch(setConnectionStatus("connected"));
                    };
                    const onClose = () => {
                        console.warn("%cCONNECTION CLOSE", "color: salmon");
                        dispatch(setConnectionStatus("disconnected"));
                    };
                    const onError = (event: any) => {
                        console.warn("Error", event);
                        dispatch(setConnectionStatus("error"));
                    };
                    const onMessage = (event: MessageEvent) => {
                        const data = JSON.parse(event.data);
                        console.warn("RECEIVED ", JSON.stringify(data, null, 2));
                        dispatch(setConnectionLoading(false));
                        const currentState = getState() as RootState;
                        const { currentUser } = currentState.chat;
                        const { userId } = currentState.auth;

                        switch (data.msg_type) {
                            case MessageTypes.IsTyping:
                                console.warn("User is typing", currentUser);
                                if (currentUser === Number(data.user_pk)) {
                                    dispatch(setIsTyping(true));
                                }
                                break;

                            case MessageTypes.TypingStopped:
                                if (currentUser === Number(data.user_pk)) {
                                    dispatch(setIsTyping(false));
                                }
                                break;
                            case MessageTypes.MessageRead:
                                console.warn("Message read");
                                break;
                            case MessageTypes.GetMessageList:
                                const recipient = data.recipient as UserType;
                                console.warn("Get message list", recipient);
                                dispatch(setMessages(data.messages));
                                dispatch(setChatUser(recipient.id));
                                dispatch(setCurrentUserName(`${recipient.first_name} ${recipient.last_name}`));
                                dispatch(setNextPage(data.next));
                                break;
                            case MessageTypes.TextMessage:
                                if (currentUser) {
                                    dispatch(setNewMessage(data.message));
                                }

                                const nbo = data.message?.nbo;

                                dispatch(api.util.invalidateTags([DIALOGS_LIST_TAG] as never));

                                if (nbo && nbo.user === userId) {
                                    dispatch(setNBOCreated(true));
                                    dispatch(api.util.invalidateTags([PROJECT_DETAILS_TAG, PENDING_NBO_TAG] as never));
                                }
                                // update messages with new nbo status
                                if (nbo && nbo.status !== "pending") {
                                    dispatch(setMessageNboStatus({ nboId: nbo.id, status: nbo.status }));
                                    if (nbo.status === "accepted") {
                                        dispatch(api.util.invalidateTags([ACCEPTED_NBO_TAG] as never));
                                    }
                                }
                                break;
                            case MessageTypes.WentOnline:
                                dispatch(
                                    chatApi.util.updateQueryData("getDialogs", undefined, draftPosts => {
                                        const connectionsState = draftPosts as DialogsResponse;
                                        const currentDialog = connectionsState.results.find(item => item.recipient.id === Number(data.user_pk));

                                        if (currentDialog) {
                                            currentDialog.recipient.is_online = true;
                                        }
                                    })
                                );
                                break;
                            case MessageTypes.WentOffline:
                                dispatch(
                                    chatApi.util.updateQueryData("getDialogs", undefined, draftPosts => {
                                        const connectionsState = draftPosts as DialogsResponse;
                                        const currentDialog = connectionsState.results.find(item => item.recipient.id === Number(data.user_pk));

                                        if (currentDialog) {
                                            currentDialog.recipient.is_online = false;
                                        }
                                    })
                                );
                                break;
                            case MessageTypes.NewUnreadCount:
                                const newUnread = data.unread_count;
                                const dialogId = data.dialog_id;

                                dispatch(
                                    chatApi.util.updateQueryData("getDialogs", undefined, draftPosts => {
                                        const connectionsState = draftPosts as DialogsResponse;
                                        const currentDialog = connectionsState.results.find(item => item.id === Number(dialogId));

                                        if (currentDialog) {
                                            currentDialog.unread_messages = newUnread;
                                        }
                                    })
                                );

                                break;
                            case MessageTypes.DialogCreated:
                                const dialog = data.dialog;

                                if (userId !== dialog.recipient.id) {
                                    dispatch(setGoToDialog(dialog.id));
                                }

                                break;
                        }
                    };

                    try {
                        await cacheDataLoaded;

                        socket.addEventListener("close", onClose);
                        socket.addEventListener("error", onError);
                        socket.addEventListener("message", onMessage);
                        socket.addEventListener("open", onOpen);
                    } catch (error) {
                        console.warn("Error occurred", error);
                    }
                    await cacheEntryRemoved;
                    socket.close();
                },
                providesTags: [SOCKET_TAG]
            })
        })
    });

export const { useGetSocketConnectionQuery, useLazyGetSocketConnectionQuery } = wsApi;

import { Avatar, Heading, Text, VStack } from "@chakra-ui/react";

import { useGetUserDetailsQuery } from "../../home/api/usersApi";

export const CompanyDetails = () => {
    const { data: user } = useGetUserDetailsQuery();
    const { company } = user || {};

    const companyFields = [
        {
            title: "Company's logo",
            content: company?.logo ? <Avatar size='xl' name={company?.name} /> : <Avatar size='xl' name={company?.name} bg='green.400' />
        },
        {
            title: "Company name",
            content: <Text>{company?.name}</Text>
        },
        {
            title: "Company's webpage",
            content: <Text>{company?.website || "-"}</Text>
        },
        {
            title: "VAT number",
            content: <Text>{company?.vat}</Text>
        },
        {
            title: "Address",
            content: <Text>{company?.address}</Text>
        },
        {
            title: "Country",
            content: <Text>{company?.country.name}</Text>
        },
        {
            title: "Role",
            content: <Text>{[company?.is_investor && "Investor", company?.is_developer && "Developer"].filter(item => item).join(", ")}</Text>
        }
    ];
    return (
        <VStack gap='4'>
            {companyFields.map(({ title, content }) => (
                <VStack key={title} w='100%' gap='2' alignItems='flex-start'>
                    <Heading size='sm'>{title}</Heading>
                    {content}
                </VStack>
            ))}
        </VStack>
    );
};

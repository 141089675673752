import { IconType } from ".";

export const AIIcon = ({ size = 24, color = "black" }: IconType) => (
    <svg width={size} height={size} fill='none'>
        <path
            fill={color}
            d='m14.207 8.852.755 1.886a.4.4 0 0 0 .743 0l.754-1.886a2.5 2.5 0 0 1 1.393-1.393l1.886-.754a.4.4 0 0 0 0-.743l-1.886-.755a2.5 2.5 0 0 1-1.393-1.392l-.754-1.887a.4.4 0 0 0-.743 0l-.755 1.887a2.5 2.5 0 0 1-1.392 1.392l-1.886.755a.4.4 0 0 0 0 .743l1.886.754a2.5 2.5 0 0 1 1.392 1.393ZM18.317 19.293l.52 1.3a.175.175 0 0 0 .326 0l.52-1.3c.111-.278.332-.499.61-.61l1.3-.52a.175.175 0 0 0 0-.326l-1.3-.52a1.095 1.095 0 0 1-.61-.61l-.52-1.3a.175.175 0 0 0-.326 0l-.52 1.3a1.095 1.095 0 0 1-.61.61l-1.3.52a.175.175 0 0 0 0 .326l1.3.52c.278.111.499.332.61.61Z'
        />
        <path
            fill={color}
            d='m7.263 19.97.74 1.852a.4.4 0 0 0 .743 0l.741-1.853a6.25 6.25 0 0 1 3.482-3.482l1.853-.74a.4.4 0 0 0 0-.743l-1.853-.741a6.25 6.25 0 0 1-3.482-3.482l-.74-1.853a.4.4 0 0 0-.743 0l-.741 1.853a6.25 6.25 0 0 1-3.482 3.482l-1.853.74a.4.4 0 0 0 0 .743l1.853.741a6.25 6.25 0 0 1 3.482 3.482Z'
        />
    </svg>
);


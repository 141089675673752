import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, HStack, IconButton, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo_text_white.png";

export const TermsConditions = () => {
    const [markdown, setMarkdown] = useState("");

    useEffect(() => {
        //@ts-ignore
        import("../../../utils/terms-conditions.md")
            .then(res => fetch(res.default))
            .then(res => res.text())
            .then(text => setMarkdown(text));
    }, []);
    return (
        <Box bg='green.500' px='4' py='8'>
            <Box maxWidth='40rem' mx='auto' color='white' position='relative'>
                <HStack justifyContent='center' alignItems='center' mb='3rem'>
                    <Link to='/' style={{ position: "absolute", left: 0 }}>
                        <IconButton aria-label='Send email' color='white' icon={<ChevronLeftIcon color='green.400' />} isRound bg='white' />
                    </Link>
                    <Image src={logo} alt='logo' w='15rem' />
                </HStack>

                <Markdown>{markdown}</Markdown>
            </Box>
        </Box>
    );
};

// import { config } from "../../config/config";
// import { setToken } from "modules/onboarding/data/authSlice";
import { type QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { type BaseQueryFn, type FetchArgs, type FetchBaseQueryError, type FetchBaseQueryMeta, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { api as cleanharbourApi } from "../api/api";
import { config } from "../config";
import { setToken } from "../modules/auth/data/authSlice";
import { getRefreshTokenData } from "../modules/auth/services/authStorage";
import { RootState } from "../store/state";

export const socketProtocol = config.isDevEnv ? "ws://" : "wss://";
export const HOST_NAME = config.isDevEnv ? "localhost:8000" : "server.cleanharbour.io";

const baseQuery = fetchBaseQuery({
    baseUrl: config.apiEndpoint,
    prepareHeaders: (headers, api) => {
        const { authToken } = (api.getState() as RootState).auth;
        if (authToken) {
            headers.set("authorization", `Bearer ${authToken}`);
        }
        return headers;
    },
    paramsSerializer: params => {
        const entries = Object.entries(params);
        const finalString = [];
        for (const [key, value] of entries) {
            // In case we have multiple values for the same key (e.g. ?makeId=2&makeId=3)
            if (typeof value === "object" && Boolean(value)) {
                for (const item of value) {
                    finalString.push(`${key}=${item}`);
                }
            } else {
                if (value) {
                    finalString.push(`${key}=${value}`);
                }
            }
        }
        return finalString.join("&");
    }
});

export const baseQueryWithReAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        const refreshToken = getRefreshTokenData();
        if (!refreshToken) {
            api.dispatch(setToken(null));
            api.dispatch(cleanharbourApi.util.resetApiState());
            // logout route
            window.location.href = "/";
        }

        type QueryResult = QueryReturnValue<{ access: string }, FetchBaseQueryError, FetchBaseQueryMeta>;

        const refreshResult = (await baseQuery(
            {
                url: "/auth/token/refresh/",
                method: "POST",
                body: { refresh: refreshToken }
            },
            api,
            extraOptions
        )) as QueryResult;

        if (refreshResult.data) {
            api.dispatch(setToken(refreshResult.data.access));
            // retry the initial query
            result = await baseQuery(args, api, extraOptions);
        } else {
            api.dispatch(setToken(null));
            api.dispatch(cleanharbourApi.util.resetApiState());
            window.location.href = "/";
        }
    }
    return result;
};

import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
    Box,
    Button,
    Flex,
    HStack,
    Heading,
    IconButton,
    Spinner,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack
} from "@chakra-ui/react";
import { CategoryScale, Chart as ChartJS, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from "chart.js";
import { useRef } from "react";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";

import { PageLayout } from "../../../components/PageLayout";
import { useGetLastNewsQuery } from "../../news/api/newsApi";
import { NewsCard } from "../../news/components/NewsCard";
import { ProjectCard } from "../../projects/components/ProjectCard";
import { useGetDashboardDetailsQuery } from "../api/dashboardApi";
import { RightPanel } from "../components/RightPanel";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
    responsive: true,
    elements: {
        point: {
            radius: 0
        }
    },
    scales: {
        x: {
            grid: {
                display: false
            }
        },
        y: {
            grid: {
                display: true
            }
        }
    },
    plugins: {
        legend: {
            position: "top" as const,
            display: false
        },
        title: {
            display: false,
            text: "Chart.js Line Chart"
        }
    }
};

export const Dashboard = () => {
    const { data: dashboardData, isLoading } = useGetDashboardDetailsQuery();
    const { data: last_news, isLoading: loadingNews } = useGetLastNewsQuery();

    const scrollRef = useRef<HTMLDivElement>(null);

    const onScroll = (scrollOffset: number) => {
        if (!scrollRef.current) return;
        const scrollLeft = scrollRef.current.scrollLeft;
        const scrollWidth = scrollRef.current.scrollWidth;
        const maxScrollLeft = scrollWidth - scrollRef.current.clientWidth;
        const newScrollLeft = Math.max(0, Math.min(scrollLeft + scrollOffset, maxScrollLeft));
        scrollRef.current.scrollTo({
            left: newScrollLeft,
            behavior: "smooth"
        });
    };

    if (isLoading || loadingNews)
        return (
            <Box display='flex' alignItems='center' justifyContent='center' h='95vh' w='full' bg='white' borderRadius='xl'>
                <VStack>
                    <Spinner size='xl' />
                    <h2>Building your dashboard...</h2>
                </VStack>
            </Box>
        );

    if (!dashboardData)
        return (
            <PageLayout title='Dashboard'>
                <Box>Something went wrong</Box>
            </PageLayout>
        );

    const { projects, commodities, accepted_nbos, pending_nbos, portfolio_overview } = dashboardData;

    return (
        <PageLayout title='Dashboard'>
            <Flex gap='.8rem' alignItems='flex-start' direction={{ base: "column", md: "row" }} w='full'>
                <Tabs variant='soft-rounded' colorScheme='green' size='sm' w={{ base: "100%", md: "80%" }}>
                    <TabList>
                        <Tab>Overview</Tab>
                        <Tab>News</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel px='0'>
                            <HStack spacing='.8rem' w='full' alignItems='flex-start'>
                                <VStack w='full' justifyContent='flex-start' spacing='.8rem'>
                                    <DashboardCard title='New deals'>
                                        <HStack position='absolute' right='1rem' top='0.5rem'>
                                            <IconButton
                                                aria-label='left'
                                                icon={<ChevronLeftIcon />}
                                                onClick={() => onScroll(-400)}
                                                size={"sm"}
                                                rounded={"full"}
                                            />
                                            <IconButton
                                                aria-label='right'
                                                icon={<ChevronRightIcon />}
                                                onClick={() => onScroll(400)}
                                                size={"sm"}
                                                rounded={"full"}
                                            />
                                        </HStack>
                                        <HStack spacing='2' w='full' alignItems='flex-start' overflowX='hidden' ref={scrollRef}>
                                            {projects ? (
                                                projects.map(project => (
                                                    <Box minW='20rem' key={project.id}>
                                                        <ProjectCard project={project} />
                                                    </Box>
                                                ))
                                            ) : (
                                                <Stack w='full'>
                                                    <Text fontSize='sm' fontWeight='bold'>
                                                        No projects available yet
                                                    </Text>
                                                </Stack>
                                            )}
                                        </HStack>
                                    </DashboardCard>
                                    <DashboardCard title='Performance overview'>
                                        <VStack spacing='2rem' w='full' alignItems='flex-start' overflowX='auto'>
                                            <Line data={portfolio_overview} options={options} />;
                                        </VStack>
                                    </DashboardCard>
                                </VStack>
                            </HStack>
                        </TabPanel>
                        <TabPanel px='0'>
                            <DashboardCard title='Top news' link={{ text: "See all", to: "/news" }}>
                                <VStack spacing='2rem' w='full' alignItems='flex-start' overflowX='auto'>
                                    {last_news?.results.map(news => <NewsCard key={news.id} news={news} />)}
                                </VStack>
                            </DashboardCard>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <RightPanel commodities={commodities} pendingNbos={pending_nbos} acceptedNbos={accepted_nbos} />
            </Flex>
        </PageLayout>
    );
};

type DashboardCardProps = {
    children: React.ReactNode;
    title: string;
    link?: {
        to: string;
        text: string;
    };
};

const DashboardCard = ({ children, title, link }: DashboardCardProps) => {
    return (
        <Stack bg='white' borderRadius='xl' p='3' flexGrow='1' h='full' w='full' overflow='auto' position='relative'>
            <HStack w='full' justifyContent='space-between'>
                <Heading size='md' mb='2'>
                    {title}
                </Heading>
                {link && (
                    <Button size='sm' variant='link' as={Link} to={link.to}>
                        {link.text}
                    </Button>
                )}
            </HStack>
            {children}
        </Stack>
    );
};

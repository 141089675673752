import { ArrowBackIcon } from "@chakra-ui/icons";
import { Box, Circle, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

import { MailIcon } from "../../../assets/icons/mail";

export const EmailVerification = () => {
    const location = useLocation();
    const { email } = location.state;

    return (
        <Box h='100vh'>
            <VStack h='100%' alignItems='center' p='20'>
                <Circle p='3' bg='green.100' size='4rem'>
                    <Circle p='2' bg='green.300' size='3rem'>
                        <MailIcon color={"green"} />
                    </Circle>
                </Circle>
                <Heading size='lg'>Check your email</Heading>
                <Text>We sent a verification link to {email}</Text>
                <Link to='/login'>
                    <HStack spacing='2' alignItems='center'>
                        <ArrowBackIcon color='green.500' />
                        <Text color='green.500'>Back to log in</Text>
                    </HStack>
                </Link>
            </VStack>
        </Box>
    );
};
